@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), url('fonts/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Free';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fonts/fa-solid-900.woff2") format("woff2"), url("fonts/fa-solid-900.ttf") format("truetype");

}

@font-face {
  font-family: 'Font Awesome 6 Free';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fonts/fa-regular-400.woff2") format("woff2"), url("fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  /*font-weight: 400;*/
  font-display: block;
  src:  url("fonts/fa-brands-400.woff2") format("woff2"), url("fonts/fa-brands-400.ttf") format("truetype");
}


@font-face {
  font-family: 'imaged-reality';
  src: local('Imaged Reality'), url('fonts/imaged-reality.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/Ubuntu-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/Ubuntu-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/Ubuntu-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/Ubuntu-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/Ubuntu-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/Ubuntu-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/Ubuntu-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/Ubuntu-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
}

.font-awesome-free-solid {
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
}

.font-awesome-free-regular {
  font-family: 'Font Awesome 6 Free';
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
}

.font-awesome-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
}

.imaged-reality {
  font-family: 'imaged-reality';
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
}

body, html,#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

#root{
  background-color: #ffffff;
}

* {
  font-size: 16px;
  font-family: 'Ubuntu', sans-serif;
}

.flex-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.sc-login{
  width: 400px;
  margin: 0 auto 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  background-color: #fff;
  border-radius: 4px;
}

#form-login{
  margin-top: 20px;
}

.btn-stbx-primary{
  color: #fff !important;
  background-color: #45a4d0 !important;
  border-color: #45a4d0 !important;
}

.btn-stbx-primary:hover{
  background-color: #429cc6 !important;
  border-color: #429cc6 !important;
}

.login-title{
  /*font-weight: medium;*/
  /*color: #495057;*/
  text-align: center;
}

.login-subtitle{
  text-align: center;
}

.header-logo{
  /*padding-bottom: 4rem;*/
  /*padding-top: 4rem;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-logo img{
  width:200px;
}

.text-09{
  font-size: 0.9rem !important;
}

#login-submit{
  font-weight: 500 !important;
  font-size: 0.95rem !important;
}


#email:disabled{
  opacity: 0.8 !important;
}

/*#password{*/
/*  border-right-width: 0px !important;*/
/*}*/

.password-visibility{
  border: 1px solid #ced4da;
  border-left-width: 0px;
  border-radius: 0 0.25rem 0.25rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.password-visibility i{
  margin: 0 8px;
}

footer{
  width: 400px;
  margin: 0px auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  flex-direction: column;
  border-top: rgb(223, 225, 230) 1px solid;
}

.footer-logo{
  margin: 20px 0px;
}

.footer-logo img{
  width: 150px;
  margin: auto;
}

.link{
  font-size: 0.8rem;
  text-align: center;
}

.recaptcha div{
  margin:auto;
}

.form-verify{
  text-align: center;
}

.avatar-row-button:hover {
  background-color: #eaeaea;
}

/*::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

.cartItemRow {
  background-color: #ffffff;
}
.cartItemRow:hover {
  background-color: #eaeaea;
}

.cartItemRemove {
  color: #db2928;
}

.cartItemRemove:hover {
  background-color: #ffffff;
}

.spinner {
  height: 1rem;
  width: 1rem;
  border: 0.25rem #1a6ca7 solid;
  background-color: transparent;
  border-color: #1a6ca7 #1a6ca7 #1a6ca7 transparent;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}


.custom-select {
  position: relative;
  display: inline-block;
  /*width: 14rem; !* Adjust width as needed *!*/
}

.custom-select select {
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Remove default arrow in Safari */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  width: 100%;
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  font-size: 16px;
  cursor: pointer;
}

.custom-select::after {
  /*content: '⇋'; !* Custom arrow *!*/
  content: '⌃'; /* Custom arrow */
  position: absolute;
  top: 50%;
  right: 0rem; /* Adjust to position the arrow */
  transform: translateY(-50%) rotate(180deg);
  pointer-events: none; /* Allow click through */
  font-weight: 700;
  font-size: 18px; /* Adjust size of the arrow */
  color: #59595c; /* Adjust color of the arrow */
}

.support-table-row {
  background-color: #FFFFFF;
}

.support-table-row:hover {
  background-color: #F4F4F4;
}

.support-table-row.active {
  background-color: #F4F4F4;
}

*:focus {
  outline: 0;
}